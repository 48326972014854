var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_c('div',{staticClass:"card-header d-flex justify-content-between"},[_vm._m(0),(_vm.validasi(['is_admin', 'spt-add']))?_c('span',{staticClass:"btn btn-primary",on:{"click":_vm.addHandle}},[_vm._v(" Tambah Baru ")]):_vm._e()]),_c('div',{staticClass:"card-body"},[(_vm.validasi(['is_admin']))?_c('div',[_c('div',{staticClass:"form-row"},[_c('b-col',{staticClass:"mb-3",attrs:{"md":"12"}},[_c('label',{attrs:{"for":"jenis_dinas"}},[_vm._v("Tahun")]),_c('v-select',{staticClass:"style-chooser",attrs:{"options":_vm.tahun_laporan},on:{"option:selected":function($event){return _vm.tahunSelected($event)}},model:{value:(_vm.filter.tahun_laporan),callback:function ($$v) {_vm.$set(_vm.filter, "tahun_laporan", $$v)},expression:"filter.tahun_laporan"}})],1)],1)]):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 10,
              position: 'top',
              perPageDropdown: [ 10, 20, 50],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'Selanjutnya',
              prevLabel: 'Sebelumnya',
              rowsPerPageLabel: 'Jumlah baris',
              ofLabel: 'dari',
              pageLabel: 'halaman', // for 'pages' mode
              allLabel: 'Semua',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',_vm._l(((props.row.name).split('_')),function(item){return _c('li',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),0):(props.column.field === 'status')?_c('span',[_c('span',{class:props.row.badge},[_vm._v(_vm._s(props.row.status))])]):(props.column.field == 'action')?_c('span',[(!props.row.proceed_at && _vm.validasi(['is_admin', 'spt-edit']))?_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"primary","title":"Edit"},on:{"click":function($event){return _vm.sptHandle(props, 'spt')}}},[_c('i',{staticClass:"dripicons dripicons-pencil-alt"}),_vm._v(" Edit SPT ")]):_vm._e(),_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"warning","title":"Edit"},on:{"click":function($event){return _vm.sptHandle(props, 'sppd')}}},[_c('i',{staticClass:"dripicons dripicons-pencil-alt"}),_vm._v(" Detail ")]),(props.row.spt_file)?_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"success","title":"Cetak SPT"},on:{"click":function($event){return _vm.cetakSPTHandle(props)}}},[_c('i',{staticClass:"dripicons dripicons-pencil-alt"}),_vm._v("Cetak SPT ")]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("Data SPT")])])
}]

export { render, staticRenderFns }