<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">Data SPT</h4>
            </div>
            <span @click="addHandle" class="btn btn-primary" v-if="validasi(['is_admin', 'spt-add'])">
              Tambah Baru
            </span>
          </div>
          <div class="card-body">
            <div v-if="validasi(['is_admin'])">
              <div class="form-row">
                  <b-col md="12" class="mb-3">
                    <label for="jenis_dinas">Tahun</label>
                    <v-select :options="tahun_laporan" @option:selected="tahunSelected($event)" v-model="filter.tahun_laporan" class="style-chooser">
                    </v-select>
                  </b-col>
                </div>
            </div>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :line-numbers="true"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [ 10, 20, 50],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'Selanjutnya',
                prevLabel: 'Sebelumnya',
                rowsPerPageLabel: 'Jumlah baris',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'name'">
                   <li v-for="item in (props.row.name).split('_')" :key="item">
                     {{ item}}
                  </li>
                </span>
                <span v-else-if="props.column.field === 'status'">
                  <span v-bind:class="props.row.badge">{{ props.row.status }}</span>
                </span>
                <span v-else-if="props.column.field == 'action'">
                  <b-button size="sm" @click="sptHandle(props, 'spt')" v-if="!props.row.proceed_at && validasi(['is_admin', 'spt-edit'])" variant="primary" class="mr-2" title="Edit">
                    <i class="dripicons dripicons-pencil-alt"></i> Edit SPT
                  </b-button >
                  <b-button size="sm" @click="sptHandle(props, 'sppd')" variant="warning" class="mr-2" title="Edit">
                    <i class="dripicons dripicons-pencil-alt"></i> Detail
                  </b-button >
                  <b-button size="sm" @click="cetakSPTHandle(props)" v-if="props.row.spt_file" variant="success" class="mr-2" title="Cetak SPT">
                    <i class="dripicons dripicons-pencil-alt"></i>Cetak SPT
                  </b-button >
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { getDataService } from '../../store/modules/crudservices'
  import { VueGoodTable } from 'vue-good-table'
  import { url_img } from '../../config/config'
  import { cekAkses } from '../../config/authenticate'

  export default {
    name:'SPTList',
    data () {
      return {
        filter: {
          tahun_laporan: ''
        },
        tahun_laporan: [],
        modalTitle: '',
        lblAnggaran: '',
        columns:[{
            label: 'No. SPT',
            field: 'no_spt',
            width: '150px',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: '',
            },
          }, {
            label: 'Nama',
            field: 'name',
            width: '280px',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: '',
            },
          }, {
            label: 'Tgl. Dinas',
            field: 'tgl',
            width: '140px',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: '',
            },
          }, {
            label: 'Tujuan',
            field: 'daerah_tujuan',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: '',
            },
          }, {
            label: 'Status',
            field: 'status',
            width: '100px',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              filterDropdownItems: [
                { value: 'Konsep', text: 'Konsep' },
                { value: 'Proses', text: 'Proses' },
                { value: 'Kembali', text: 'Kembali' },
                { value: 'Kwitansi', text: 'Kwitansi' },
                { value: 'Selesai', text: 'Selesai' },
                { value: 'Void', text: 'Void' }
              ],
              trigger: '',
            },
          }, {
            label: 'Keterangan',
            width: '140px',
            field: 'keterangan',
          }, {
            label: 'Aksi',
            field: 'action',
            sortable: false,
            // width: '350px',
            tdClass: 'text-center',
          }
        ],
        rows: [],
        form:{
          name: null
        }
      }
    },
    methods: {
      tahunSelected(item) {
        this.filter.tahun_laporan = item
        this.refreshLists()
      },
      refreshLists(){
        getDataService('/spt-grid?tahun=' + this.filter.tahun_laporan).then(data => {
          this.rows = data.data
        })
      },
      cetakSPTHandle(data) {
        const url = `/spt/${data.row.id}/cetak`
        getDataService(url).then(response => {
          
          let link = document.createElement("a")
          link.href = url_img + response.data
          link.download = (new Date).toLocaleDateString() + "_spt.pdf"
          link.target = "_blank"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      },
      sptHandle(data, direction) {
        let url = `/spt/${data.row.id}`
        let sppd = direction === 'sppd' ? '/sppd' : '/edit'
        this.$router.push(url + sppd) 
      },
      addHandle() {
        this.$router.push('/spt/add')
      },
      validasi(perms) {
        return cekAkses(perms)
      },
    },
    components: {
      VueGoodTable,
    },
    mounted() {
      const option = []
      let init_year = 2021;
      let year = (new Date()).getFullYear();
      while (init_year <= year) {
        option.push(init_year)
        init_year++;
      }
      this.tahun_laporan = option
      this.filter.tahun_laporan = year
      this.refreshLists()
    }
  }
</script>